<template>
  <div class="row">
    <div class="col-md-8 full-height">
      <div class="row">
        <div
          class="col-md-3"
          @click="selectCorporation(corporation)"
          v-for="corporation in corporations"
          :key="corporation.id"
        >
          <corporation-card :corporation="corporation"></corporation-card>
        </div>
      </div>
    </div>
    <div class="col-md-4" v-if="corporations">
      <div class="mb-4 d-flex align-items-center justify-content-between">
        <base-button @click="refresh" slot="footer" type="secondary" fill
          >Refresh</base-button
        >
        <base-button @click="add" slot="footer" type="secondary" fill
          >Add New</base-button
        >
      </div>
      <edit-corporation-form @clearModel="clearModelData" :model="model">
      </edit-corporation-form>
    </div>
  </div>
</template>
<script>
import EditCorporationForm from "./Corporation/EditCorporationForm";
import CorporationCard from "./Corporation/CorporationCard";
import store from "../store";
import nasidaLogo from "../assets/logo.svg";

export default {
  components: {
    EditCorporationForm,
    CorporationCard,
  },
  data() {
    return {
      model: null,
    };
  },
  mounted() {
    store.dispatch("getAllCorporations");
  },
  methods: {
    selectCorporation(corporation) {
      this.model = corporation;
    },
    add() {
      this.model = { type: "add", logo: nasidaLogo };
    },
    refresh() {
      store.dispatch("getAllCorporations");
    },
    clearModelData() {
      this.model = null;
    },
  },
  computed: {
    corporations() {
      return store.state.corporationsModule.corporations;
    },
    loading() {
      return store.state.loadingModule.loading;
    },
  },
};
</script>
<style></style>
