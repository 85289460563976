<template>
  <div class="row">
    <div class="col-md-8 full-height">
      <div class="row">
        <div
          class="col-md-3"
          @click="selectService(service)"
          v-for="s in services"
          :key="s.id"
        >
          <service-card :service="service"></service-card>
        </div>
      </div>
    </div>
    <div class="col-md-4" v-if="services">
      <div class="mb-4 d-flex align-items-center justify-content-between">
        <base-button @click="refresh" slot="footer" type="secondary" fill
          >Refresh</base-button
        >
        <base-button @click="add" slot="footer" type="secondary" fill
          >Add New</base-button
        >
      </div>
      <edit-service-form @clearModel="clearModelData" :model="model">
      </edit-service-form>
    </div>
  </div>
</template>


<script>
import EditServiceForm from "./Service/EditServiceForm";
import ServiceCard from "./Service/ServiceCard";
import store from "../store";
import nasidaLogo from "../assets/logo.svg";

export default {
  components: {
    EditServiceForm,
    ServiceCard,
  },
  data() {
    return {
      model: null,
    };
  },
  mounted() {
    store.dispatch("getAllServices");
  },
  methods: {
    selectService(service) {
      this.model = service;
    },
    add() {
      this.model = { type: "add", pdf: nasidaLogo };
    },
    refresh() {
      store.dispatch("getAllCorporations");
    },
    clearModelData() {
      this.model = null;
    },
  },
  computed: {
    services() {
      return store.state.servicesModule.services;
    },
    loading() {
      return store.state.loadingModule.loading;
    },
  },
};
</script>
<style></style>
