<template>
  <card>
    <div class="d-flex pointer flex-column">
      <div class="corporation-image">
        <img :src="corporation.logo" />
      </div>
      <a class="mx-auto button" :href="corporation.website_url" target="_blank"
        ><i class="tim-icons icon-world"></i
      ></a>
    </div>
  </card>
</template>

<script>
export default {
  props: {
    corporation: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style>
.button {
  background-color: #389466;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  margin-top: 4px;
}

i:hover {
  color: white;
}

.corporation-image {
  height: 60px;
  margin-bottom: 4px;
}
.corporation-image img {
  object-fit: contain;
}
</style>
