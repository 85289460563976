<template>
  <card class="p-2" v-if="model">
    <h3 slot="header" class="title">{{ action }} Corporation</h3>
    <div class="row">
      <div class="col-md-6 uploadable">
        <label
          for="logo_upload"
          class="upload d-flex align-items-center justify-content-center"
        >
          <p>Click to upload</p>
          <input
            @change="uploadLogo"
            type="file"
            class="d-none"
            id="logo_upload"
          />
        </label>
        <img :src="photo" />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-10">
        <base-input
          label="Website URL"
          placeholder="Website URL"
          v-model="model.website_url"
        >
        </base-input>
      </div>
    </div>
    <base-button
      v-if="model.type == 'add'"
      @click="create"
      slot="footer"
      type="secondary"
      fill
      >Save</base-button
    >
    <base-button
      v-if="model.type != 'add'"
      @click="update"
      slot="footer"
      type="secondary"
      fill
      >Update</base-button
    >
    <base-button
      v-if="model.type != 'add'"
      @click="deleteCorporation"
      slot="footer"
      type="warning"
      class="ml-4"
      fill
      >Delete</base-button
    >
  </card>
  <card v-else>
    <div
      class="d-flex flex-column align-items-center justify-content-center full user-select-none"
    >
      <i class="tim-icons icon-alert-circle-exc text"></i>
      <h4 class="text-uppercase text-center font-weight-bold my-4">
        Select a Company to view details
      </h4>
    </div>
  </card>
</template>
<script>
import store from "../../store";
export default {
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      logo: null,
      newLogo: null,
      newLogoURL: null,
    };
  },
  mounted() {
    this.logo = this.model?.logo;
  },
  computed: {
    photo: {
      get() {
        return this.logo;
      },
      set(newValue) {
        this.logo = newValue;
      },
    },
    action() {
      return this.model.type == "add" ? "Add" : "Edit";
    },
  },
  watch: {
    model(newModel) {
      if (newModel.type == "add") {
        this.photo = this.newLogoURL || newModel?.logo;
      } else {
        this.photo = newModel?.logo;
      }
    },
  },
  methods: {
    create() {
      const formData = new FormData();
      if (this.newLogo) {
        formData.append("logo", this.newLogo);
        formData.append("website_url", this.model.website_url);

        store
          .dispatch("createCorporation", formData)
          .then((response) => {
            this.$emit("clearModel");
            this.$notify({
              message: response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "success",
              timeout: 0,
            });
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "warning",
              timeout: 0,
            });
          });
      } else {
        alert("Please upload an image to continue");
      }
    },
    update() {
      store
        .dispatch("updateCorporation", this.model)
        .then((response) => {
          this.$emit("clearModel");
          this.$notify({
            message: response.data.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "left",
            verticalAlign: "bottom",
            type: "success",
            timeout: 0,
          });
        })
        .catch((error) => {
          this.$notify({
            message: error.response.data.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "left",
            verticalAlign: "bottom",
            type: "warning",
            timeout: 0,
          });
        });
    },
    uploadLogo(event) {
      const photo = event.target.files[0];
      this.photo = URL.createObjectURL(photo);
      this.newLogoURL = this.photo;

      if (this.model.type != "add") {
        const formData = new FormData();
        formData.append("id", this.model.id);
        formData.append("logo", photo);

        store
          .dispatch("updateCorporationLogo", formData)
          .then((response) => {
            this.$notify({
              message: response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "success",
              timeout: 0,
            });
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "warning",
              timeout: 0,
            });
          });
      } else {
        this.newLogo = photo;
      }
    },
    deleteCorporation() {
      const decision = window.confirm(
        "Are you sure you want to delete this corporation?"
      );
      if (decision) {
        store
          .dispatch("deleteCorporation", this.model)
          .then((response) => {
            this.$emit("clearModel");
            this.$notify({
              message: response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "success",
              timeout: 0,
            });
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "warning",
              timeout: 0,
            });
          });
      }
    },
  },
};
</script>
<style></style>
