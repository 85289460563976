<template>
  <card>
    <div class="d-flex pointer flex-column">
      <div class="service-image">
        <p>{{service.title}}</p>
      </div>
      <a class="mx-auto button" :href="service.pdf" target="_blank"
        ><i class="tim-icons icon-world"></i
      ></a>
    </div>
  </card>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style>
.button {
  background-color: #389466;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  margin-top: 4px;
}

i:hover {
  color: white;
}

.service-image {
  height: 60px;
  margin-bottom: 4px;
}

.service-image p{
  color: black;
}
</style>
